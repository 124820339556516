import { useEffect, useState } from 'react';

import classNames from 'classnames';

import BpkSelectableChip from '@skyscanner/backpack-web/bpk-component-chip';
import BpkChevronDown from '@skyscanner/backpack-web/bpk-component-icon/sm/chevron-down';
import BpkChevronUp from '@skyscanner/backpack-web/bpk-component-icon/sm/chevron-up';

import CustomNavDesktopItem from './CustomNavDesktopItem';
import CustomNavMobileItem from './CustomNavMobileItem';

import type { CustomNavProps } from 'common-types/types/CustomNavProps';

import STYLES from './CustomNav.module.scss';

export default function CustomNav({
  menuItems,
  mobileToggleMenuLabel,
  mobileVisitParentPageLabel,
}: CustomNavProps) {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [openDesktopSubMenu, setOpenDesktopSubMenu] = useState<string | null>(
    null,
  );

  useEffect(() => {
    if (mobileMenuOpen) {
      document.body.classList.add('custom-menu-open');
    } else {
      document.body.classList.remove('custom-menu-open');
    }
  }, [mobileMenuOpen]);

  return (
    <nav className={STYLES.CustomNav}>
      <div className={STYLES.Grid}>
        {/** Mobile Menu */}
        <div className={STYLES.MobileMenuToggle}>
          <BpkSelectableChip
            accessibilityLabel="Press to open menu"
            type="on-dark"
            selected={mobileMenuOpen}
            trailingAccessoryView={
              mobileMenuOpen ? <BpkChevronUp /> : <BpkChevronDown />
            }
            onClick={() => {
              setMobileMenuOpen(!mobileMenuOpen);
            }}
          >
            {mobileToggleMenuLabel}
          </BpkSelectableChip>
        </div>

        <div
          data-testid="news-nav-mobile"
          className={classNames(
            STYLES.MobileMenu,
            mobileMenuOpen && STYLES['MobileMenu--open'],
          )}
        >
          {menuItems.map((menuItem) => (
            <CustomNavMobileItem
              key={menuItem.id}
              menuItem={menuItem}
              mobileVisitParentPageLabel={mobileVisitParentPageLabel}
            />
          ))}
        </div>

        {/** Desktop Menu */}
        <div
          data-testid="news-nav-desktop"
          className={STYLES.DesktopMenu}
          onBlur={(e) => {
            if (!e.currentTarget.contains(e.relatedTarget)) {
              setOpenDesktopSubMenu(null);
            }
          }}
        >
          {menuItems.map((menuItem) => (
            <CustomNavDesktopItem
              key={menuItem.id}
              menuItem={menuItem}
              onOpen={setOpenDesktopSubMenu}
              openMenuItemId={openDesktopSubMenu}
            />
          ))}
        </div>
      </div>
    </nav>
  );
}
